import React from "react";
import "./Sidebar.css"; // Import the CSS file
import { RiNeteaseCloudMusicLine } from "react-icons/ri";
import { HiOutlineMusicNote } from "react-icons/hi";
import { BiGroup, BiBell } from "react-icons/bi";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { SiAnalogue } from "react-icons/si";
import { FiHelpCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FaGear } from "react-icons/fa6";
import { GrLogout } from "react-icons/gr";

function Sidebar() {
  const handleLogout = () => {
    localStorage.removeItem("token"); // Clear the token
    window.location.href = "/"; // Redirect to the login page
  };
  return (
    <aside id="sidebar" style={{ position: "sticky", top: 0, left: 0 }}>
      <div className="brand" style={{ marginBottom: "4rem" }}>
        <Link to="/">
          <img
            src="https://www.fttunes.com/Images/ft-tunes.png"
            className="logo_img"
            style={{ background: "#fff" }}
            alt="fttunes logo"
          />
          {/* <img
            src="/Images/favicon.ico"
            className="logo_icon"
            alt="fttunes logo"
          /> */}
        </Link>
      </div>
      <ul className="links" style={{ padding: 0 }}>
        <li
        // className="selected"
        >
          <Link to="/dashboard">
            <SiAnalogue />
            <p className="show_on_hover">Dashboard</p>
          </Link>
        </li>
        <li>
          {" "}
          <Link to="/artists">
            <BiGroup />
            <p className="show_on_hover">Artists</p>{" "}
          </Link>
        </li>
        <li>
          {" "}
          <Link to="/fttracks">
            <HiOutlineMusicNote />
            <p className="show_on_hover">FT Tracks</p>{" "}
          </Link>
        </li>
        {/* <li>
          {" "}
          <Link to="/notifications">
            <BiBell />
            <p className="show_on_hover">Notifications</p>{" "}
          </Link>
        </li> */}
        <li>
          {" "}
          <Link to="/payouts">
            <AiOutlineDollarCircle />
            <p className="show_on_hover">Payouts</p>{" "}
          </Link>
        </li>
        <li>
          {" "}
          <Link to="/settings">
            <FaGear />
            <p className="show_on_hover">Settings</p>{" "}
          </Link>
        </li>
        <li>
          {" "}
          <Link onClick={() => handleLogout()}>
            <GrLogout />
            <p className="show_on_hover">Logout</p>{" "}
          </Link>
        </li>
        {/* <li>
          {" "}
          <Link to="/users">
            <BiGroup />
            <p className="show_on_hover">Users</p>{" "}
          </Link>
        </li> */}
      </ul>
      {/* <div className="help" style={{ marginTop: "2rem" }}>
        <Link to="/help">
          <FiHelpCircle />
          <p className="show_on_hover">Help</p>
        </Link>
      </div> */}
    </aside>
  );
}

export default Sidebar;
