import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";

const UploadReportID = () => {
  const { id } = useParams(); // Get the artist ID from the URL params
  const [formData, setFormData] = useState({
    artistId: id,
    file: null,
    startTime: "",
    endTime: "",
    remarks: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({
        ...formData,
        file: files[0], // Handle file upload
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setIsSubmitting(true);

    // Basic validation
    if (!formData.file || !formData.startTime || !formData.endTime) {
      setError("Please fill in all required fields and upload the report.");
      setIsSubmitting(false);
      return;
    }

    try {
      // const formDataToSubmit = new FormData();
      // formDataToSubmit.append("artistId", formData.artistId);
      // formDataToSubmit.append("file", formData.file); // File
      // formDataToSubmit.append("startTime", formData.startTime);
      // formDataToSubmit.append("endTime", formData.endTime);
      // formDataToSubmit.append("remarks", formData.remarks);
      // formDataToSubmit = formData;

      console.log("formdata submitted", formData);

      const response = await axios.post(
        "https://fttunes.com/api/reports/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("report upload response", response);

      if (response.status === 201) {
        setSuccess("Report uploaded successfully!");
        setFormData({
          artistId: id,
          file: null,
          startTime: "",
          endTime: "",
          remarks: "",
        });
      } else {
        setError("Failed to upload the report.");
      }
    } catch (error) {
      console.error("Error uploading report:", error);
      setError("An error occurred while uploading the report.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card bg="dark" text="white">
            <Card.Body>
              <Card.Title className="text-center">
                Upload Report for Artist
              </Card.Title>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group controlId="formStartTime">
                      <Form.Label>Report Start Time</Form.Label>
                      <Form.Control
                        type="date"
                        name="startTime"
                        value={formData.startTime}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formEndTime">
                      <Form.Label>Report End Time</Form.Label>
                      <Form.Control
                        type="date"
                        name="endTime"
                        value={formData.endTime}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="formFile">
                  <Form.Label>Upload Report (PDF/Excel)</Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleChange}
                    accept=".pdf,.xlsx,.xls"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formRemarks">
                  <Form.Label>Remarks (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                    rows={3}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className="mt-3 theme-btn"
                  block
                >
                  {isSubmitting ? "Uploading..." : "Upload Report"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UploadReportID;
