import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Form,
  Button,
  Col,
  Modal,
  Row,
} from "react-bootstrap";
import { FaEye } from "react-icons/fa6";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";


const Payouts = () => {
  const [payouts, setPayouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPayout, setSelectedPayout] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch all payouts on component mount
  useEffect(() => {
    const fetchPayouts = async () => {
      try {
        const response = await axios.get(`https://fttunes.com/api/payouts`);
        console.log("Fetched payouts:", response.data);
        setPayouts(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch payouts");
        setLoading(false);
      }
    };

    fetchPayouts();
  }, []);

  const handleViewDetails = (payout) => {
    setSelectedPayout(payout);
    setShowModal(true);
  };

  if (loading) {
    return <div>Loading payouts...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Col className="artists-table" style={{ margin: "30px 20px" }}>
      <Card>
        <Card.Header>
          <h4 className="card-title">
            <mark>Payouts</mark>
          </h4>
          <Button
            as={Link}
            to="/add-payout"
            className="theme-btn add_album_btn"
          >
            Make Payout
          </Button>
          {/* You can add a button here to create new payouts if needed */}
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table responsive="md" variant="dark">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>
                    <Form.Check
                      type="checkbox"
                      id="checkAllPayouts"
                      className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                      style={{ border: "none" }}
                      label=""
                    />
                  </th>
                  <th>ID</th>
                  <th>Artist</th>
                  <th>Payout Date</th>
                  <th>Amount</th>
                  <th>Remarks</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {payouts.map((payout, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        id={`customCheckBoxPayout${index + 2}`}
                        className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                        label=""
                        style={{ border: "none" }}
                      />
                    </td>
                    <td>
                      <strong>{payout.payoutId}</strong>
                    </td>
                    <td>
                      {payout.firstName} {payout.lastName} ({payout.artistId})
                    </td>
                    <td>{new Date(payout.payoutDate).toLocaleDateString()}</td>
                    <td>₹ {payout.amount}</td>
                    <td>{payout.remarks}</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleViewDetails(payout)}
                      >
                        <FaEye /> View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>

      {/* Modal to show payout details */}
      {selectedPayout && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          centered
          className="change_status_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{color:"#fff"}}>Payout Details - {selectedPayout.payoutId}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{color:"#fff"}}>
              <Col md={6}>
                <h5>Artist Information</h5>
                <p>
                  <strong>Name:</strong> {selectedPayout.firstName}{" "}
                  {selectedPayout.lastName}
                </p>
                <p>
                  <strong>Artist ID:</strong> {selectedPayout.artistId}
                </p>
              </Col>
              <Col md={6}>
                <h5>Payout Information</h5>
                <p>
                  <strong>Payout Date:</strong>{" "}
                  {new Date(selectedPayout.payoutDate).toLocaleDateString()}
                </p>
                <p>
                  <strong>Amount:</strong> ₹ {selectedPayout.amount}
                </p>
                <p>
                  <strong>Payout Period:</strong>{" "}
                  {new Date(
                    selectedPayout.payoutStartDate
                  ).toLocaleDateString()}{" "}
                  -{" "}
                  {new Date(selectedPayout.payoutEndDate).toLocaleDateString()}
                </p>
                <p>
                  <strong>Remarks:</strong> {selectedPayout.remarks}
                </p>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            {/* Add any additional actions if necessary */}
          </Modal.Footer>
        </Modal>
      )}
    </Col>
  );
};

export default Payouts;
