import React from "react";
import ActiveListeners from "./ActiveListeners";
import Avatar from "./Avatar";
import Player from "./Player";
import "./RightSidebar.css"; // Import the CSS file

function RightSidebar() {
  return (
    <section id="rightSidebar" className="right-sidebar">
      {" "}
      {/* Add right-sidebar class here */}
      <Avatar />
      <ActiveListeners />
      <Player />
    </section>
  );
}

export default RightSidebar;
