import React, { useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import "./TopReleases.css";
import "./ReusableStyles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function TopReleases() {
  const music = [
    {
      title: "Ayesha Rain",
      plays: 64,
      image: "Images/Artists/ayesha-rain.jpg",
    },
    {
      title: "Manish Albela",
      plays: 56,
      image: "Images/Artists/manish-albela.jpg",
    },
    { title: "Micle Amit", plays: 32, image: "Images/Artists/micle-amit.jpg" },
    {
      title: "Rajeshwari Singh",
      plays: 25,
      image: "Images/Artists/rajeshwari-singh.jpg",
    },
    {
      title: "Ritik Gupta",
      plays: 36,
      image: "Images/Artists/ritik-gupta.jpg",
    },
    {
      title: "Roy Razneesh",
      plays: 12,
      image: "Images/Artists/roy-razneesh.jpg",
    },
  ];

  const [artists, setArtists] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the artist profile based on the id
    const fetchArtistsData = async () => {
      try {
        const response = await axios.get(`https://fttunes.com/api/artists`);
        // console.log("response", response);
        setArtists(response.data); // Initialize form data with artist profile from API
      } catch (error) {
        console.error("Error fetching artist data:", error);
      }
    };

    fetchArtistsData();
  }, []);

  if (!artists) return null;

  return (
    <section id="toprelease" className="section card-style">
      {" "}
      {/* Add card-style class here */}
      <div className="title-container">
        <div className="title">
          <h4>Top Artists</h4>
        </div>
        <div className="filters">
          {/* <span onClick={()=>{navigate("/artists")}}>All Artists</span>
          <button>New Artists</button> */}
          <button
            onClick={() => {
              navigate("/artists");
            }}
            className="theme-btn" style={{color:"white"}}
          >
            All Artists
          </button>
        </div>
      </div>
      <div className="musics">
        {artists.map(
          ({ firstName, lastName, plays, profileImage, artistId, index }) => (
            <div
              className="music"
              key={index}
              onClick={() => {
                navigate(`/artists/${artistId}`);
              }}
            >
              <div className="details">
                <div className="image">
                  <img
                    src={`https://fttunes.com/api/${profileImage}`}
                    alt={firstName}
                  />
                </div>
                <div className="info">
                  <h5>
                    {firstName} {lastName}
                  </h5>
                  <h6>64 Releases</h6>
                </div>
              </div>
              <BsChevronRight />
            </div>
          )
        )}
      </div>
    </section>
  );
}

export default TopReleases;
