import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import "./AddArtist.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";


const formatDateToYYYYMMDD = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const artistsData = [
  {
    id: "1",
    firstName: "Ayesha",
    lastName: "Rain",
    doj: "1992-07-15",
    gender: "female",
    profileImage: "/Images/Artists/ayesha-rain.jpg",
    description:
      "An upcoming artist with a strong passion for contemporary music.",
    websiteUrl: "https://ayesharain.com",
    email: "example@example.com",
    phone: "(+1) 408-657-9007",
    password: "password123",
    confirmPassword: "password123",
    facebookUrl: "https://facebook.com/ayesharain",
    twitterUrl: "https://twitter.com/ayesharain",
    linkedInUrl: "https://linkedin.com/ayesharain",
    spotifyUrl: "https://spotify.com/ayesharain",
    wynkUrl: "https://wynk.com/ayesharain",
    gaanaUrl: "https://gaana.com/ayesharain",
    instagramUrl: "https://instagram.com/ayesharain",
    status: "Successful",
    bankAccountName: "Ayesha Rain",
    bankAccountNumber: "123456789",
    bankName: "Bank of Music",
    ifscCode: "BOM123456",
    swiftCode: "BOMNINBB421",
  },
  {
    id: "2",
    firstName: "Manish",
    lastName: "Albela",
    doj: "1985-03-20",
    gender: "male",
    profileImage: "/Images/Artists/manish-albela.jpg",
    description:
      "A seasoned artist known for his energetic performances and unique style.",
    websiteUrl: "https://manishalbela.com",
    email: "example@example.com",
    phone: "(+1) 408-657-1234",
    password: "strongPassword456",
    confirmPassword: "strongPassword456",
    facebookUrl: "https://facebook.com/manishalbela",
    twitterUrl: "https://twitter.com/manishalbela",
    linkedInUrl: "https://linkedin.com/manishalbela",
    spotifyUrl: "https://spotify.com/manishalbela",
    wynkUrl: "https://wynk.com/manishalbela",
    gaanaUrl: "https://gaana.com/manishalbela",
    instagramUrl: "https://instagram.com/manishalbela",
    status: "Cancelled",
    bankAccountName: "Manish Albela",
    bankAccountNumber: "123456789",
    bankName: "Bank of Music",
    ifscCode: "BOM123456",
    swiftCode: "BOMNINBB421",
  },
  {
    id: "3",
    firstName: "Micle",
    lastName: "Amit",
    doj: "1990-08-05",
    gender: "male",
    profileImage: "/Images/Artists/micle-amit.jpg",
    description:
      "An experimental artist who blends different genres into his music.",
    websiteUrl: "https://micleamit.com",
    email: "example@example.com",
    phone: "(+1) 408-657-2345",
    password: "anotherPassword789",
    confirmPassword: "anotherPassword789",
    facebookUrl: "https://facebook.com/micleamit",
    twitterUrl: "https://twitter.com/micleamit",
    linkedInUrl: "https://linkedin.com/micleamit",
    spotifyUrl: "https://spotify.com/micleamit",
    wynkUrl: "https://wynk.com/micleamit",
    gaanaUrl: "https://gaana.com/micleamit",
    instagramUrl: "https://instagram.com/micleamit",
    status: "Pending",
    bankAccountName: "Micle Amit",
    bankAccountNumber: "123456789",
    bankName: "Bank of Music",
    ifscCode: "BOM123456",
    swiftCode: "BOMNINBB421",
  },
  {
    id: "4",
    firstName: "Rajeshwari",
    lastName: "Singh",
    doj: "1989-12-11",
    gender: "female",
    profileImage: "/Images/Artists/rajeshwari-singh.jpg",
    description:
      "A dynamic performer known for her soulful voice and captivating lyrics.",
    websiteUrl: "https://rajeshwarisingh.com",
    email: "example@example.com",
    phone: "(+1) 408-657-3456",
    password: "passwordExample123",
    confirmPassword: "passwordExample123",
    facebookUrl: "https://facebook.com/rajeshwarisingh",
    twitterUrl: "https://twitter.com/rajeshwarisingh",
    linkedInUrl: "https://linkedin.com/rajeshwarisingh",
    spotifyUrl: "https://spotify.com/rajeshwarisingh",
    wynkUrl: "https://wynk.com/rajeshwarisingh",
    gaanaUrl: "https://gaana.com/rajeshwarisingh",
    instagramUrl: "https://instagram.com/rajeshwarisingh",
    status: "Successful",
    bankAccountName: "Rajeshwari Singh",
    bankAccountNumber: "123456789",
    bankName: "Bank of Music",
    ifscCode: "BOM123456",
    swiftCode: "BOMNINBB421",
  },
  {
    id: "5",
    firstName: "Ritik",
    lastName: "Gupta",
    doj: "1991-02-19",
    gender: "male",
    profileImage: "/Images/Artists/ritik-gupta.jpg",
    description:
      "A talented guitarist with a knack for composing soulful melodies.",
    websiteUrl: "https://ritikgupta.com",
    email: "example@example.com",
    phone: "(+1) 408-657-4567",
    password: "guitarProPass",
    confirmPassword: "guitarProPass",
    facebookUrl: "https://facebook.com/ritikgupta",
    twitterUrl: "https://twitter.com/ritikgupta",
    linkedInUrl: "https://linkedin.com/ritikgupta",
    spotifyUrl: "https://spotify.com/ritikgupta",
    wynkUrl: "https://wynk.com/ritikgupta",
    gaanaUrl: "https://gaana.com/ritikgupta",
    instagramUrl: "https://instagram.com/ritikgupta",
    status: "Cancelled",
    bankAccountName: "Ritik Gupta",
    bankAccountNumber: "123456789",
    bankName: "Bank of Music",
    ifscCode: "BOM123456",
    swiftCode: "BOMNINBB421",
  },
  {
    id: "6",
    firstName: "Roy",
    lastName: "Razneesh",
    doj: "1987-04-25",
    gender: "male",
    profileImage: "/Images/Artists/roy-razneesh.jpg",
    description:
      "A DJ and music producer known for his electrifying beats and mixes.",
    websiteUrl: "https://royrazneesh.com",
    email: "example@example.com",
    phone: "(+1) 408-657-5678",
    password: "djRoyPass456",
    confirmPassword: "djRoyPass456",
    facebookUrl: "https://facebook.com/royrazneesh",
    twitterUrl: "https://twitter.com/royrazneesh",
    linkedInUrl: "https://linkedin.com/royrazneesh",
    spotifyUrl: "https://spotify.com/royrazneesh",
    wynkUrl: "https://wynk.com/royrazneesh",
    gaanaUrl: "https://gaana.com/royrazneesh",
    instagramUrl: "https://instagram.com/royrazneesh",
    status: "Pending",
    bankAccountName: "Roy Razneesh",
    bankAccountNumber: "123456789",
    bankName: "Bank of Music",
    ifscCode: "BOM123456",
    swiftCode: "BOMNINBB421",
  },
];

const EditArtist = () => {
  const { id } = useParams(); // Retrieve artist id from URL parameters
  const [profile, setProfile] = useState(null); // State to store the artist profile
  const [formData, setFormData] = useState({}); // State to store form data

  useEffect(() => {
    // Fetch the artist profile based on the id
    const fetchArtistData = async () => {
      try {
        const response = await axios.get(
          `https://fttunes.com/api/artists/${id}`
        );
        // console.log("response", response);
        setFormData(response.data); // Initialize form data with artist profile from API
      } catch (error) {
        console.error("Error fetching artist data:", error);
      }
    };

    fetchArtistData();
  }, [id]);

  const [step, setStep] = useState(1); // State to keep track of the current step

  // Handle input changes and update form data
  const handleInputChange = (e) => {
    // console.log("e", e.target);
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  // Function to handle next button click
  const handleNext = () => {
    if (step < 4) {
      setStep(step + 1);
    }
  };

  // Function to handle previous button click
  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission refresh

    try {
      console.log("formdata", formData);
      const response = await axios.put(
        `https://fttunes.com/api/artists/${id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("response", response.data);
      alert("Artist data updated successfully!");
      navigate("/artists");
    } catch (error) {
      console.error("Error updating artist data:", error);
      alert("Failed to update artist data.");
      navigate("/artists");
    }
  };

  // Render the form steps based on the current step
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne formData={formData} handleInputChange={handleInputChange} />
        );
      case 2:
        return (
          <StepTwo formData={formData} handleInputChange={handleInputChange} />
        );
      case 3:
        return (
          <StepThree
            formData={formData}
            handleInputChange={handleInputChange}
          />
        );
      case 4:
        return (
          <StepFour formData={formData} handleInputChange={handleInputChange} />
        );
      default:
        return null;
    }
  };

  return (
    <Container className="add-artist" style={{ padding: "2rem" }}>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h4 className="card-title">
                <mark>Edit Artist</mark>
              </h4>
              <Button
                variant="primary"
                className="theme-btn add_artist_btn"
                // style={{margin:"20px 10px"}}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <IoMdArrowRoundBack/> Back
              </Button>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div
                  id="smartwizard"
                  className="form-wizard order-create sw sw-theme-default sw-justified"
                >
                  <ul className="nav nav-wizard">
                    {[
                      "Personal Info",
                      "Contact Info",
                      "Social Links",
                      "Bank Account Details",
                    ].map((label, index) => (
                      <li key={index}>
                        <Button
                          className={`nav-link ${
                            step >= index + 1 ? "active" : "inactive"
                          }`}
                          onClick={() => setStep(index + 1)}
                        >
                          <span>{index + 1}</span>
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <div className="tab-content">{renderStep()}</div>
                  <div
                    className="toolbar toolbar-bottom"
                    role="toolbar"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      className="btn btn-primary sw-btn-prev"
                      onClick={handlePrev}
                      disabled={step === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      className="btn btn-primary sw-btn-next"
                      onClick={handleNext}
                      disabled={step === 4}
                    >
                      Next
                    </Button>
                    {step === 4 && (
                      <Button
                        type="submit"
                        className="btn btn-primary sw-btn-submit"
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

// Form Step Components

const StepOne = ({ formData, handleInputChange }) => (
  <div id="wizard_PersonalInfo" className="tab-pane">
    <Row>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            First Name<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            value={formData.firstName || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Last Name<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            value={formData.lastName || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Date of Joining<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="date"
            name="doj"
            value={formatDateToYYYYMMDD(formData.doj) || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Gender<span className="required">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            name="gender"
            value={formData.gender || ""}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </Form.Control>
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Profile Image<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="file"
            name="profileImage"
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg={12} className="mb-2">
        <Form.Group>
          <Form.Label>
            Brief Description<span className="required">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            value={formData.description || ""}
            onChange={handleInputChange}
            rows={3}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={12} className="mb-2">
        <Form.Group>
          <Form.Label>Website URL</Form.Label>
          <Form.Control
            type="url"
            name="websiteUrl"
            value={formData.websiteUrl || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
    </Row>
  </div>
);

const StepTwo = ({ formData, handleInputChange }) => (
  <div id="wizard_ContactInfo" className="tab-pane">
    <Row>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Email Address<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Phone Number<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="tel"
            name="phone"
            value={formData.phone || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Password<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={formData.password || ""}
            onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Confirm Password<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword || ""}
            onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
    </Row>
  </div>
);

const StepThree = ({ formData, handleInputChange }) => (
  <div id="wizard_SocialLinks" className="tab-pane">
    <Row>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Facebook URL</Form.Label>
          <Form.Control
            type="url"
            name="facebookUrl"
            value={formData.facebookUrl || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Twitter URL</Form.Label>
          <Form.Control
            type="url"
            name="twitterUrl"
            value={formData.twitterUrl || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>

      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Instagram URL</Form.Label>
          <Form.Control
            type="url"
            name="instagramUrl"
            value={formData.instagramUrl || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>LinkedIn URL</Form.Label>
          <Form.Control
            type="url"
            name="linkedInUrl"
            value={formData.linkedInUrl || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>

      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Spotify URL</Form.Label>
          <Form.Control
            type="url"
            name="spotifyUrl"
            value={formData.spotifyUrl || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Wynk URL</Form.Label>
          <Form.Control
            type="url"
            name="wynkUrl"
            value={formData.wynkUrl || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Gaana URL</Form.Label>
          <Form.Control
            type="url"
            name="gaanaUrl"
            value={formData.gaanaUrl || ""}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
    </Row>
  </div>
);

const StepFour = ({ formData, handleInputChange }) => (
  <div id="wizard_BankAccountDetails" className="tab-pane">
    <Row>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Bank Account Name<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="bankAccountName"
            value={formData.bankAccountName || ""}
            onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Bank Account Number<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="bankAccountNumber"
            value={formData.bankAccountNumber || ""}
            onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Bank Name<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="bankName"
            value={formData.bankName || ""}
            onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Bank Routing Number<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="ifscCode"
            value={formData.ifscCode || ""}
            onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Swift Code<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="swiftCode"
            value={formData.swiftCode || ""}
            onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
    </Row>
  </div>
);

export default EditArtist;
