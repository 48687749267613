import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Button,
  Alert,
  Image,
} from "react-bootstrap";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import axios from "axios"; // Import axios for making HTTP requests
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    setSuccess("");

    try {
      console.log("requested data", {
        adminId: email,
        password: password,
      });
      const response = await axios.post("https://fttunes.com/api/login", {
        adminId: email,
        password: password,
      });

      setSuccess(response.data.message); // Set success message
      // Handle successful login here (e.g., redirect to another page, store token, etc.)
      localStorage.setItem("token", response.data.token);
      window.location.reload();
    } catch (err) {
      if (err.response) {
        // If the error has a response (e.g., 400, 401)
        setError(err.response.data.message);
      } else {
        // If the error is from the request itself
        setError("An unexpected error occurred.");
      }
    }
  };

  return (
    // <Container
    //   style={{
    //     backgroundImage:
    //       "url('/Images/login-bg.jpg')",
    //       backgroundSize:"cover",
    //     width: "100%",
    //     padding: "0px",
    //     margin: "0px",
    //     maxWidth: "100%",
    //   }}
    // >
    //   <Row className="justify-content-center">
    //     {" "}
    //     <Image
    //       src="https://www.fttunes.com/Images/ft-tunes.png"
    //       height={100}
    //       style={{ width: "300px" }}
    //     />
    //   </Row>
    //   <Row
    //     className="text-align-center justify-content-center"
    //     style={{ alignItems: "center", height: "90vh" }}
    //   >
    //     <Col md={4}>
    //       <Card
    //         style={
    //           {
    //             // background: "#000",
    //             // color: "#fff",
    //             //  borderRadius:"30px"
    //             // border:"1px solid black"
    //           }
    //         }
    //       >
    //         <Card.Header>
    //           <Card.Title style={{ textAlign: "center", fontSize: "larger" }}>
    //             Login - Admin
    //           </Card.Title>
    //         </Card.Header>
    //         <Card.Body>
    //           {error && <Alert variant="danger">{error}</Alert>}
    //           {success && <Alert variant="success">{success}</Alert>}
    //           <Form onSubmit={handleSubmit}>
    //             <Form.Group controlId="formBasicEmail">
    //               <Form.Label>Email address</Form.Label>
    //               <Form.Control
    //                 type="email"
    //                 placeholder="Enter your email"
    //                 value={email}
    //                 onChange={(e) => setEmail(e.target.value)}
    //                 required
    //               />
    //             </Form.Group>

    //             <Form.Group controlId="formBasicPassword">
    //               <Form.Label>Password</Form.Label>
    //               <Form.Control
    //                 type="password"
    //                 placeholder="Enter your password"
    //                 value={password}
    //                 onChange={(e) => setPassword(e.target.value)}
    //                 required
    //               />
    //             </Form.Group>

    //             <Button
    //               variant="primary"
    //               type="submit"
    //               style={{
    //                 padding: "10px 20px",
    //                 marginBlock: "20px",
    //                 marginInline: "auto",
    //                 textAlign: "center",
    //                 width: "100%",
    //               }}
    //               className="theme-btn"
    //             >
    //               Submit
    //             </Button>
    //           </Form>
    //         </Card.Body>
    //       </Card>
    //     </Col>
    //   </Row>
    // </Container>
    <div
      className="authincation h-100"
      style={{ backgroundImage: "url('/Images/login-bg.jpg')" }}
    >
      <Container className="h-100">
        <Row className="justify-content-center h-100 align-items-center">
          <Col md={6}>
            <div className="authincation-content">
              <Row className="no-gutters">
                <Col xl={12}>
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <a href="/">
                        <img
                          src="https://www.fttunes.com/Images/ft-tunes.png"
                          alt=""
                          style={{ maxWidth: "200px" }}
                        />
                      </a>
                    </div>
                    <h4 className="text-center mb-4">Sign in as Admin</h4>

                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="form-group">
                        <Form.Label className="mb-1 form-label">
                          Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          className="form-control"
                          defaultValue="hello@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-4 position-relative">
                        <Form.Label className="mb-1 form-label">
                          Password
                        </Form.Label>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          id="dz-password"
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <span
                          className="show-pass eye"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                          {/* Toggle icons */}
                        </span>
                      </Form.Group>
                      {/* <div className="form-row d-flex flex-wrap justify-content-between mt-4 mb-2">
                        <Form.Group className="form-group">
                          <Form.Check
                            type="checkbox"
                            className="form-check-input"
                            id="basic_checkbox_1"
                            label="Remember my preference"
                          />
                        </Form.Group>
                        <Form.Group className="form-group ms-2">
                          <a href="/Mediqu/ForgotPassword">Forgot Password?</a>
                        </Form.Group>
                      </div> */}
                      <div className="text-center">
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Sign In
                        </Button>
                      </div>
                    </Form>
                    {/* <div className="new-account mt-3">
                      <p>
                        Don't have an account?{" "}
                        <a className="text-primary" href="/Mediqu/Register">
                          Sign up
                        </a>
                      </p>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
