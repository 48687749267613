import React from "react";
import Dashboard from "./Dashboard";
import RightSidebar from "./RightSidebar";
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <Dashboard />
      {/* <RightSidebar /> */}
    </div>
  );
};

export default Home;
