import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Table,
  Form,
} from "react-bootstrap";
import {
  RiWalletLine,
  RiWallet2Line,
  RiWallet3Line,
  RiArrowUpFill,
  RiArrowDownFill,
} from "react-icons/ri";
import { FaCircle, FaPencil, FaTrash } from "react-icons/fa6";
import "./ArtistProfile.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";
import Reports from "../reports/Reports";

const ArtistProfile = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the artist profile based on the id
    const fetchArtistData = async () => {
      try {
        const response = await axios.get(
          `https://fttunes.com/api/artists/${id}`
        );
        // console.log("response", response);
        setProfile(response.data); // Initialize form data with artist profile from API
      } catch (error) {
        console.error("Error fetching artist data:", error);
      }
    };

    fetchArtistData();
  }, [id]);

  const [albums, setAlbums] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        // Fetch artistId from localStorage
        // const artistProfile = JSON.parse(localStorage.getItem("profile"));
        // const artistId = artistProfile?.artistId;

        const artistId = id;
        if (!artistId) {
          // setError("Artist ID not found");
          return;
        }

        // Call the API to fetch albums by the artist
        const response = await axios.get(
          `https://fttunes.com/api/artists/${artistId}/albums`
        );
        setAlbums(response.data.data); // Assuming API returns albums in `data.data`
        // setLoading(false);
      } catch (err) {
        // setError("Failed to fetch albums");
        // setLoading(false);
      }
    };

    fetchAlbums();
  }, []);

  if (!profile) {
    return null;
  }

  const artistsData = [
    {
      id: "FTT2024001",
      firstName: "Ayesha",
      lastName: "Rain",
      doj: "1992-07-15",
      gender: "female",
      profileImage: "/Images/Artists/ayesha-rain.jpg",
      description:
        "An upcoming artist with a strong passion for contemporary music.",
      websiteUrl: "https://ayesharain.com",
      email: "example@example.com",
      phone: "(+1) 408-657-9007",
      password: "password123",
      confirmPassword: "password123",
      facebookUrl: "https://facebook.com/ayesharain",
      twitterUrl: "https://twitter.com/ayesharain",
      linkedInUrl: "https://linkedin.com/ayesharain",
      spotifyUrl: "https://spotify.com/ayesharain",
      wynkUrl: "https://wynk.com/ayesharain",
      gaanaUrl: "https://gaana.com/ayesharain",
      instagramUrl: "https://instagram.com/ayesharain",
      status: "Successful",
      bankAccountName: "Ayesha Rain",
      bankAccountNumber: "123456789",
      bankName: "Bank of Music",
      ifscCode: "BOM123456",
      swiftCode: "BOMNINBB421",
    },
    {
      id: "FTT2024002",
      firstName: "Manish",
      lastName: "Albela",
      doj: "1985-03-20",
      gender: "male",
      profileImage: "/Images/Artists/manish-albela.jpg",
      description:
        "A seasoned artist known for his energetic performances and unique style.",
      websiteUrl: "https://manishalbela.com",
      email: "example@example.com",
      phone: "(+1) 408-657-1234",
      password: "strongPassword456",
      confirmPassword: "strongPassword456",
      facebookUrl: "https://facebook.com/manishalbela",
      twitterUrl: "https://twitter.com/manishalbela",
      linkedInUrl: "https://linkedin.com/manishalbela",
      spotifyUrl: "https://spotify.com/manishalbela",
      wynkUrl: "https://wynk.com/manishalbela",
      gaanaUrl: "https://gaana.com/manishalbela",
      instagramUrl: "https://instagram.com/manishalbela",
      status: "Cancelled",
      bankAccountName: "Manish Albela",
      bankAccountNumber: "123456789",
      bankName: "Bank of Music",
      ifscCode: "BOM123456",
      swiftCode: "BOMNINBB421",
    },
    {
      id: "FTT2024003",
      firstName: "Micle",
      lastName: "Amit",
      doj: "1990-08-05",
      gender: "male",
      profileImage: "/Images/Artists/micle-amit.jpg",
      description:
        "An experimental artist who blends different genres into his music.",
      websiteUrl: "https://micleamit.com",
      email: "example@example.com",
      phone: "(+1) 408-657-2345",
      password: "anotherPassword789",
      confirmPassword: "anotherPassword789",
      facebookUrl: "https://facebook.com/micleamit",
      twitterUrl: "https://twitter.com/micleamit",
      linkedInUrl: "https://linkedin.com/micleamit",
      spotifyUrl: "https://spotify.com/micleamit",
      wynkUrl: "https://wynk.com/micleamit",
      gaanaUrl: "https://gaana.com/micleamit",
      instagramUrl: "https://instagram.com/micleamit",
      status: "Pending",
      bankAccountName: "Micle Amit",
      bankAccountNumber: "123456789",
      bankName: "Bank of Music",
      ifscCode: "BOM123456",
      swiftCode: "BOMNINBB421",
    },
    {
      id: "FTT2024004",
      firstName: "Rajeshwari",
      lastName: "Singh",
      doj: "1989-12-11",
      gender: "female",
      profileImage: "/Images/Artists/rajeshwari-singh.jpg",
      description:
        "A dynamic performer known for her soulful voice and captivating lyrics.",
      websiteUrl: "https://rajeshwarisingh.com",
      email: "example@example.com",
      phone: "(+1) 408-657-3456",
      password: "passwordExample123",
      confirmPassword: "passwordExample123",
      facebookUrl: "https://facebook.com/rajeshwarisingh",
      twitterUrl: "https://twitter.com/rajeshwarisingh",
      linkedInUrl: "https://linkedin.com/rajeshwarisingh",
      spotifyUrl: "https://spotify.com/rajeshwarisingh",
      wynkUrl: "https://wynk.com/rajeshwarisingh",
      gaanaUrl: "https://gaana.com/rajeshwarisingh",
      instagramUrl: "https://instagram.com/rajeshwarisingh",
      status: "Successful",
      bankAccountName: "Rajeshwari Singh",
      bankAccountNumber: "123456789",
      bankName: "Bank of Music",
      ifscCode: "BOM123456",
      swiftCode: "BOMNINBB421",
    },
    {
      id: "FTT2024005",
      firstName: "Ritik",
      lastName: "Gupta",
      doj: "1991-02-19",
      gender: "male",
      profileImage: "/Images/Artists/ritik-gupta.jpg",
      description:
        "A talented guitarist with a knack for composing soulful melodies.",
      websiteUrl: "https://ritikgupta.com",
      email: "example@example.com",
      phone: "(+1) 408-657-4567",
      password: "guitarProPass",
      confirmPassword: "guitarProPass",
      facebookUrl: "https://facebook.com/ritikgupta",
      twitterUrl: "https://twitter.com/ritikgupta",
      linkedInUrl: "https://linkedin.com/ritikgupta",
      spotifyUrl: "https://spotify.com/ritikgupta",
      wynkUrl: "https://wynk.com/ritikgupta",
      gaanaUrl: "https://gaana.com/ritikgupta",
      instagramUrl: "https://instagram.com/ritikgupta",
      status: "Cancelled",
      bankAccountName: "Ritik Gupta",
      bankAccountNumber: "123456789",
      bankName: "Bank of Music",
      ifscCode: "BOM123456",
      swiftCode: "BOMNINBB421",
    },
    {
      id: "FTT2024006",
      firstName: "Roy",
      lastName: "Razneesh",
      doj: "1987-04-25",
      gender: "male",
      profileImage: "/Images/Artists/roy-razneesh.jpg",
      description:
        "A DJ and music producer known for his electrifying beats and mixes.",
      websiteUrl: "https://royrazneesh.com",
      email: "example@example.com",
      phone: "(+1) 408-657-5678",
      password: "djRoyPass456",
      confirmPassword: "djRoyPass456",
      facebookUrl: "https://facebook.com/royrazneesh",
      twitterUrl: "https://twitter.com/royrazneesh",
      linkedInUrl: "https://linkedin.com/royrazneesh",
      spotifyUrl: "https://spotify.com/royrazneesh",
      wynkUrl: "https://wynk.com/royrazneesh",
      gaanaUrl: "https://gaana.com/royrazneesh",
      instagramUrl: "https://instagram.com/royrazneesh",
      status: "Pending",
      bankAccountName: "Roy Razneesh",
      bankAccountNumber: "123456789",
      bankName: "Bank of Music",
      ifscCode: "BOM123456",
      swiftCode: "BOMNINBB421",
    },
  ];
  const items = [
    {
      id: 1,
      imgSrc: "	https://enftx.vercel.app/images/items/1.jpg",
      avatarSrc: "	https://enftx.vercel.app/images/avatar/1.jpg",
      title: "Liquid Wave",
      auctionTime: "3h 1m 50s",
      currentBid: "0.15 ETH",
    },
    {
      id: 2,
      imgSrc: "	https://enftx.vercel.app/images/items/2.jpg",
      avatarSrc: "	https://enftx.vercel.app/images/avatar/2.jpg",
      title: "Liquid Wave",
      auctionTime: "3h 1m 50s",
      currentBid: "0.15 ETH",
    },
    {
      id: 3,
      imgSrc: "	https://enftx.vercel.app/images/items/3.jpg",
      avatarSrc: "	https://enftx.vercel.app/images/avatar/3.jpg",
      title: "Liquid Wave",
      auctionTime: "3h 1m 50s",
      currentBid: "0.15 ETH",
    },
    {
      id: 4,
      imgSrc: "	https://enftx.vercel.app/images/items/4.jpg",
      avatarSrc: "	https://enftx.vercel.app/images/avatar/4.jpg",
      title: "Liquid Wave",
      auctionTime: "3h 1m 50s",
      currentBid: "0.15 ETH",
    },
    {
      id: 5,
      imgSrc: "	https://enftx.vercel.app/images/items/5.jpg",
      avatarSrc: "	https://enftx.vercel.app/images/avatar/5.jpg",
      title: "Liquid Wave",
      auctionTime: "3h 1m 50s",
      currentBid: "0.55 ETH",
    },
    {
      id: 6,
      imgSrc: "	https://enftx.vercel.app/images/items/6.jpg",
      avatarSrc: "	https://enftx.vercel.app/images/avatar/6.jpg",
      title: "Liquid Wave",
      auctionTime: "3h 1m 50s",
      currentBid: "0.15 ETH",
    },
    {
      id: 7,
      imgSrc: "	https://enftx.vercel.app/images/items/7.jpg",
      avatarSrc: "	https://enftx.vercel.app/images/avatar/7.jpg",
      title: "Liquid Wave",
      auctionTime: "3h 1m 50s",
      currentBid: "0.15 ETH",
    },
    {
      id: 8,
      imgSrc: "	https://enftx.vercel.app/images/items/8.jpg",
      avatarSrc: "	https://enftx.vercel.app/images/avatar/8.jpg",
      title: "Liquid Wave",
      auctionTime: "3h 1m 50s",
      currentBid: "0.15 ETH",
    },
  ];
  const stats = [
    {
      id: 1,
      icon: <RiWalletLine />,
      count: "24K",
      label: "Artworks",
      percentage: "+168.001%",
      arrowDirection: "up",
      bgColor: "primary",
    },
    {
      id: 2,
      icon: <RiWallet2Line />,
      count: "82K",
      label: "Auction",
      percentage: "+168.001%",
      arrowDirection: "down",
      bgColor: "secondary",
    },
    {
      id: 3,
      icon: <RiWallet3Line />,
      count: "1K",
      label: "Creators",
      percentage: "+168.001%",
      arrowDirection: "up",
      bgColor: "success",
    },
  ];
  // const albums = [
  //   {
  //     id: "001",
  //     title: "Echoes of Silence",
  //     releaseDate: "10 January 2021",
  //     genre: "Alternative",
  //     status: "Released",
  //     statusClass: "success",
  //   },
  //   {
  //     id: "002",
  //     title: "Celestial Harmony",
  //     releaseDate: "24 March 2022",
  //     genre: "Ambient",
  //     status: "Upcoming",
  //     statusClass: "warning",
  //   },
  //   {
  //     id: "003",
  //     title: "Electric Dreams",
  //     releaseDate: "15 August 2020",
  //     genre: "Synthwave",
  //     status: "Released",
  //     statusClass: "success",
  //   },
  //   {
  //     id: "004",
  //     title: "Mystic River",
  //     releaseDate: "05 May 2019",
  //     genre: "Folk",
  //     status: "Released",
  //     statusClass: "success",
  //   },
  //   {
  //     id: "005",
  //     title: "Future Nostalgia",
  //     releaseDate: "18 November 2023",
  //     genre: "Pop",
  //     status: "Upcoming",
  //     statusClass: "warning",
  //   },
  // ];

  const handleDeleteAlbum = async (id) => {
    if (window.confirm("Are you sure you want to delete this album?")) {
      try {
        await axios.delete(`https://fttunes.com/api/albums/${id}`);
        setAlbums(albums.filter((album) => album.id !== id));
      } catch (error) {
        console.error("Error deleting album:", error);
      }
    }
  };
  // const [profile, setProfile] = useState(artistsData[0]);
  // useEffect(() => {
  //   const artistProfile = artistsData.find((artist) => artist.artistId === id);
  //   console.log("artistprofile", artistProfile);
  //   setProfile(artistProfile);
  //   console.log("profile", profile);
  // }, [id]);

  return (
    <div
      className="artist-profile"
      style={{ padding: "2rem", background: "#000" }}
    >
      <Container>
        <Row>
          <Col className="artists-table">
            <Card>
              <Card.Header>
                {/* <Button
                  variant="primary"
                  className="theme-btn add_artist_btn"
                  // style={{ margin: "20px 10px" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <IoMdArrowRoundBack/> Back
                </Button> */}
                <h4 className="card-title">
                  <mark>My Releases</mark>
                </h4>
                <Button
                  as={Link}
                  to="/artist/releases/create"
                  className="theme-btn add_album_btn"
                >
                  Add New Release
                </Button>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <Table responsive="md" variant="dark">
                    <thead>
                      <tr>
                        <th style={{ width: "50px" }}>
                          <Form.Check
                            type="checkbox"
                            id="checkAllAlbums"
                            className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                            style={{ border: "none" }}
                            label=""
                          />
                        </th>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Release Date</th>
                        <th>Genre</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {albums.map((album, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Check
                              type="checkbox"
                              id={`customCheckBoxAlbum${index + 2}`}
                              className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                              label=""
                              style={{ border: "none" }}
                            />
                          </td>
                          <td>
                            <strong>{album.id}</strong>
                          </td>
                          <td>
                            <Link
                              to={`/artist/album/${album.id}`}
                              style={{ textDecoration: "", color: "#fff" }}
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  src={`https://fttunes.com/api/${album.uploadPoster}`}
                                  className="rounded-lg me-2"
                                  width="24"
                                  alt=""
                                />
                                <span className="w-space-no">
                                  {album.songName}
                                </span>
                              </div>
                            </Link>
                          </td>
                          <td>
                            {new Date(album.liveDate).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </td>
                          <td>{album.songLanguage}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <FaCircle
                                className={`text-${
                                  album.status === "Released"
                                    ? "success"
                                    : album.status === "Rejected"
                                    ? "danger"
                                    : album.status === "Approved"
                                    ? "approved"
                                    : "warning"
                                } me-1`}
                              />
                              {album.status}
                            </div>
                          </td>

                          <td>
                            {album.status === "Pending" && (
                              <div className="d-flex">
                                <Button
                                  variant="primary"
                                  size="sm"
                                  className="me-1"
                                  style={{ padding: "0.625rem 1rem" }}
                                  onClick={() =>
                                    navigate(
                                      `/artist/releases/edit/${album.id}`
                                    )
                                  }
                                >
                                  <FaPencil />
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  style={{ padding: "0.625rem 1rem" }}
                                  onClick={() => handleDeleteAlbum(album.id)}
                                >
                                  <FaTrash />
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="m-0">
              <Card.Header>
                <h4 className="card-title">Stats</h4>
              </Card.Header>
              <Card.Body className="bs-0 bg-transparent p-0">
                <Row>
                  {stats.map((stat) => (
                    <Col key={stat.id} md={4} sm={6}>
                      <div className="stat-widget d-flex align-items-center">
                        <div className={`widget-icon me-3 bg-${stat.bgColor}`}>
                          <span>{stat.icon}</span>
                        </div>
                        <div className="widget-content">
                          <h3>{stat.count}</h3>
                          <p>{stat.label}</p>
                        </div>
                        <div className="widget-arrow">
                          <p
                            className={`text-${
                              stat.arrowDirection === "up"
                                ? "success"
                                : "danger"
                            } mb-0`}
                          >
                            {stat.percentage}{" "}
                            <span>
                              {stat.arrowDirection === "up" ? (
                                <RiArrowUpFill />
                              ) : (
                                <RiArrowDownFill />
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card className="top-bid">
              <Card.Body>
                <Row className="align-items-center">
                  <Col md={6}>
                    <Image
                      src={`https://fttunes.com/api/${profile.profileImage}`}
                      className="img-fluid rounded"
                      alt="Item"
                    />
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center mb-3">
                      <Image
                        src={`https://fttunes.com/api/${profile.profileImage}`}
                        alt="Avatar"
                        className="me-3 avatar-img"
                        roundedCircle
                      />
                      <div className="flex-grow-1">
                        <h6 className="mb-0">
                          {profile.firstName} {profile.lastName}{" "}
                          <span className="circle bg-success"></span>
                        </h6>
                      </div>
                    </div>
                    <h4 className="card-title">Music Composer</h4>
                    <div className="d-flex justify-content-between mt-3 mb-3">
                      <div className="text-start">
                        <p className="mb-2">Email</p>
                        <h5 className="text-muted">abc@gmail.com</h5>
                      </div>
                      <div className="text-end">
                        <p className="mb-2">
                          Phone <strong className="text-primary"></strong>
                        </p>
                        <h5 className="text-muted">+91 9876543210</h5>
                      </div>
                    </div>
                    <div className="">
                      Music is an art form, social activity or cultural activity
                      whose medium is sound and silence. The common elements of
                      music are pitch (which governs melody and harmony), rhythm
                      (and its associated concepts tempo, meter, and
                      articulation), dynamics (loudness and softness), and the
                      sonic qualities of timbre and texture (which are sometimes
                      termed the "color" of a musical sound). Different styles
                      or types of music may emphasize, de-emphasize or omit some
                      of these elements. Music is performed with a vast range of
                      instruments and with vocal techniques ranging from singing
                      to rapping, and there are solely instrumental pieces,
                      solely vocal pieces and pieces that combine singing and
                      instruments.
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                      <Button variant="primary" className="me-2">
                        Add Album
                      </Button>
                      <Button variant="secondary">Remove Artist</Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Reports artistId={profile.artistId} />

          {/* <Col xs={12}>
            <Card className="filter-tab m-0">
              <Card.Header>
                <h4 className="card-title">Latest Releases</h4>
                <div className="filter-nav">
                  <a href="#" className="active">
                    All
                  </a>
                  <a href="#">Hindi</a>
                  <a href="#">Bhojpuri</a>
                  <a href="#">Bhakti</a>
                </div>
              </Card.Header>
              <Card.Body className="bs-0 p-0 bg-transparent">
                <Row>
                  {items.map((item) => (
                    <Col
                      key={item.id}
                      xxl={3}
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      className="mb-4"
                    >
                      <Card className="items">
                        <Card.Body>
                          <div className="items-img position-relative">
                            <Image
                              src={item.imgSrc}
                              className="img-fluid rounded mb-3"
                              alt={item.title}
                            />
                            <Image
                              src={profile.profileImage}
                              className="creator"
                              width={50}
                              alt="Creator"
                            />
                          </div>
                          <h4 className="card-title">{item.title}</h4>
                          <div className="d-flex justify-content-between">
                            <div className="text-start">
                              <p className="mb-2">Views Count</p>
                              <h5 className="text-muted">10.6K</h5>
                            </div>
                            <div className="text-end">
                              <p className="mb-2">
                                Release Date{" "}
                                <strong className="text-primary">
                                </strong>
                              </p>
                              <h5 className="text-muted">Aug 10, 2024</h5>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mt-3">
                            <Button variant="primary">View Album</Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default ArtistProfile;
