import React from "react";
import { jwtDecode } from "jwt-decode";
import Login from "./components/login/Login";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return <Login />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // current time in seconds

    if (decodedToken.exp < currentTime) {
      // Token is expired
      localStorage.removeItem("token"); // Clear expired token
      return <Login />; // Redirect to Login
    }
  } catch (error) {
    // If there's an error decoding the token (e.g., corrupted token), treat it as expired
    localStorage.removeItem("token");
    return <Login />;
  }

  return children;
};

export default ProtectedRoute;
