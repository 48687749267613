import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Form,
  Button,
  Col,
  Dropdown,
  Modal,
  Row,
  Image,
  ListGroup,
} from "react-bootstrap";
import "./AllArtistsTable.css";
import { FaCircle, FaPencil, FaTrash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios
import { IoMdArrowRoundBack } from "react-icons/io";

const AllArtistsTable = () => {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        const response = await axios.get("https://fttunes.com/api/artists");
        console.log(" artist response", response);
        const sortedArtists = response.data.sort((a, b) => {
          // Move artists with "Pending" status to the top
          if (a.status === "Pending" && b.status !== "Pending") return -1;
          if (a.status !== "Pending" && b.status === "Pending") return 1;
          return 0;
        });
        setArtists(sortedArtists);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArtists();
  }, [showModal, showModal2]);

  const handleDelete = async (artistId) => {
    if (window.confirm("Are you sure you want to delete this artist?")) {
      try {
        await axios.delete(`https://fttunes.com/api/artists/${artistId}`);
        setArtists((prevArtists) =>
          prevArtists.filter((artist) => artist.artistId !== artistId)
        );
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handleChangeStatus = async (artistId, updatedStatus) => {
    try {
      const response = await axios.patch(
        `https://fttunes.com/api/artists/status/${artistId}`,
        { updatedStatus }
      );

      setArtists((prevArtists) =>
        prevArtists.map((artist) =>
          artist.artistId === artistId
            ? { ...artist, status: response.data.updatedStatus }
            : artist
        )
      );

      setShowModal(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleViewAlbums = (artistId) => {
    navigate(`/artists/${artistId}`);
  };
  const handleViewNotification = (artistId) => {
    navigate(`/artists/${artistId}`);
  };

  const openChangeStatusModal = (artist) => {
    setSelectedArtist(artist);
    setShowModal(true);
  };

  const openUploadDocsModal = (artist) => {
    setSelectedArtist(artist);
    setShowModal2(true);
  };

  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setUploadedDocs([...uploadedDocs, ...files]);
  };

  const handleSelectDoc = (file) => {
    setSelectedDoc(file);
  };

  const handleRemoveDoc = (index) => {
    const updatedDocs = uploadedDocs.filter((_, i) => i !== index);
    setUploadedDocs(updatedDocs);

    // If the removed document was the selected one, clear the preview
    if (selectedDoc && uploadedDocs[index] === selectedDoc) {
      setSelectedDoc(null);
    }
  };

  const handleUploadDocuments = async () => {
    if (!selectedArtist || uploadedDocs.length === 0) {
      return;
    }

    const formData = new FormData();
    uploadedDocs.forEach((doc) => formData.append("documents", doc));

    try {
      console.log("formdata", formData);
      const response = await axios.post(
        `https://fttunes.com/api/artists/${selectedArtist.artistId}/documents`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload response:", response.data);
      setShowModal2(false);
      setUploadedDocs([]);
      setSelectedDoc(null);
      window.alert("Documents uploaded successfully!");
    } catch (err) {
      console.error("Upload error:", err.message);
      window.alert(`Error uploading documents: ${err.message}`);
      // setError(err.message);
    }
  };

  return (
    <Col className="artists-table">
      <Card>
        <Card.Header>
          {/* <Col md={12}> */}
          <Button
            variant="primary"
            className="theme-btn add_artist_btn"
            // style={{margin:"20px 10px"}}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoMdArrowRoundBack /> Back
          </Button>
          {/* </Col> */}
          <h4 className="card-title">
            <mark>Artists</mark>
          </h4>
          <Button
            as={Link}
            to="/add-artist"
            className="theme-btn add_artist_btn"
          >
            Add Artist
          </Button>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <div className="table-responsive">
              <Table responsive="md" variant="dark">
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}>
                      <Form.Check
                        type="checkbox"
                        id="checkAll"
                        className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                        style={{ border: "none" }}
                        label=""
                      />
                    </th>
                    <th>ID.</th>
                    <th>NAME</th>
                    <th>Email</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {artists.map((artist, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          id={`customCheckBox${index + 2}`}
                          className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                          label=""
                          style={{ border: "none" }}
                        />
                      </td>
                      <td>
                        <strong>{artist.artistId}</strong>
                      </td>
                      <td>
                        <Link
                          to={`/artists/${artist.artistId}`}
                          style={{ textDecoration: "", color: "#fff" }}
                        >
                          <div className="d-flex align-items-center">
                            <img
                              // src={artist.profileImage}
                              src={`https://fttunes.com/api/${artist.profileImage}`}
                              className="rounded-lg me-2"
                              width="24"
                              alt=""
                            />
                            <span className="w-space-no">
                              {artist.firstName} {artist.lastName}
                            </span>
                          </div>
                        </Link>
                      </td>
                      <td>{artist.email}</td>
                      <td>{artist.createdAt}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <FaCircle
                            className={`text-${
                              artist.status === "Approved"
                                ? "success"
                                : artist.status === "Rejected"
                                ? "danger"
                                : "warning"
                            } me-1`}
                          />{" "}
                          {artist.status}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Button
                            variant="primary"
                            size="sm"
                            className="me-1"
                            onClick={() =>
                              navigate(`/artists/edit/${artist.artistId}`)
                            }
                          >
                            <FaPencil />
                          </Button>
                          {/* <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(artist.artistId)}
                          >
                            <FaTrash />
                          </Button> */}

                          <Dropdown>
                            <Dropdown.Toggle
                              variant="secondary"
                              size="sm"
                              className="me-1"
                            >
                              Action
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => handleDelete(artist.artistId)}
                              >
                                Remove Artist
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => openChangeStatusModal(artist)}
                              >
                                Change Status
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => openUploadDocsModal(artist)}
                              >
                                Upload Documents
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleViewAlbums(artist.artistId)
                                }
                              >
                                View Albums
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  navigate(`/add-fttrack/${artist.artistId}`)
                                }
                              >
                                Upload FT Tracks
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  navigate(`/add-payout/${artist.artistId}`)
                                }
                              >
                                Make Payout
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  navigate(`/upload-report/${artist.artistId}`)
                                }
                              >
                                Upload Report
                              </Dropdown.Item>
                              {/* <Dropdown.Item
                                onClick={() =>
                                  handleViewNotification(artist.artistId)
                                }
                              >
                                Notification
                              </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Card.Body>
      </Card>

      {selectedArtist && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="xl"
          className="change_status_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#fff" }}>Change Status</Modal.Title>
          </Modal.Header>
          <Modal.Body className="artist-profile">
            <Row className="align-items-center">
              <Col>
                <Card className="top-bid">
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <Image
                          src={`https://fttunes.com/api/${selectedArtist.profileImage}`}
                          className="img-fluid rounded"
                          alt="Item"
                        />
                      </Col>
                      <Col md={6}>
                        <div className="d-flex align-items-center mb-3">
                          <Image
                            src={`https://fttunes.com/api/${selectedArtist.profileImage}`}
                            alt="Avatar"
                            className="me-3 avatar-img"
                            roundedCircle
                          />
                          <div className="flex-grow-1">
                            <h6 className="mb-0">
                              {selectedArtist.firstName}{" "}
                              {selectedArtist.lastName}{" "}
                              <span className="circle bg-success"></span>
                            </h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mb-3">
                          {/* <Button variant="primary" className="me-2">
                            Add Album
                          </Button>
                          <Button variant="secondary">Remove Artist</Button> */}

                          {selectedArtist.status !== "Approved" && (
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleChangeStatus(
                                  selectedArtist.artistId,
                                  "Approved"
                                )
                              }
                            >
                              Approve
                            </Button>
                          )}

                          {selectedArtist.status !== "Pending" && (
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleChangeStatus(
                                  selectedArtist.artistId,
                                  "Pending"
                                )
                              }
                            >
                              Add to Pending
                            </Button>
                          )}

                          {selectedArtist.status !== "Rejected" && (
                            <Button
                              variant="secondary"
                              onClick={() =>
                                handleChangeStatus(
                                  selectedArtist.artistId,
                                  "Rejected"
                                )
                              }
                            >
                              Reject
                            </Button>
                          )}
                        </div>
                        <h4 className="card-title">Music Composer</h4>
                        <div className="d-flex justify-content-between mt-3 mb-3">
                          <div className="text-start">
                            <p className="mb-2">Email</p>
                            <h5 className="text-muted">abc@gmail.com</h5>
                          </div>
                          <div className="text-end">
                            <p className="mb-2">
                              Phone <strong className="text-primary"></strong>
                            </p>
                            <h5 className="text-muted">+91 9876543210</h5>
                          </div>
                        </div>
                        <div className="">
                          Music is an art form, social activity or cultural
                          activity whose medium is sound and silence. The common
                          elements of music are pitch (which governs melody and
                          harmony), rhythm (and its associated concepts tempo,
                          meter, and articulation), dynamics (loudness and
                          softness), and the sonic qualities of timbre and
                          texture (which are sometimes termed the "color" of a
                          musical sound). Different styles or types of music may
                          emphasize, de-emphasize or omit some of these
                          elements. Music is performed with a vast range of
                          instruments and with vocal techniques ranging from
                          singing to rapping, and there are solely instrumental
                          pieces, solely vocal pieces and pieces that combine
                          singing and instruments.
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          {/* <Modal.Footer>
            {selectedArtist.status !== "Approved" && (
              <Button
                variant="success"
                onClick={() =>
                  handleChangeStatus(selectedArtist.artistId, "Approved")
                }
              >
                Approve
              </Button>
            )}

            {selectedArtist.status !== "Pending" && (
              <Button
                variant="warning"
                onClick={() =>
                  handleChangeStatus(selectedArtist.artistId, "Rejected")
                }
              >
                Add to Pending
              </Button>
            )}

            {selectedArtist.status !== "Rejected" && (
              <Button
                variant="danger"
                onClick={() =>
                  handleChangeStatus(selectedArtist.artistId, "Rejected")
                }
              >
                Reject
              </Button>
            )}
          </Modal.Footer> */}
        </Modal>
      )}

      {selectedArtist && (
        <Modal
          show={showModal2}
          onHide={() => {
            setShowModal2(false);
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>Select Documents</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Form>

            {uploadedDocs.length > 0 && (
              <>
                <h5>Uploaded Documents</h5>
                <div className="d-flex">
                  <ListGroup className="flex-grow-1">
                    {uploadedDocs.map((doc, index) => (
                      <ListGroup.Item
                        key={index}
                        onClick={() => handleSelectDoc(doc)}
                        style={{ cursor: "pointer" }}
                      >
                        {doc.name}
                        <Button
                          variant="danger"
                          size="sm"
                          style={{ float: "right" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveDoc(index);
                          }}
                        >
                          &times;
                        </Button>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                  {selectedDoc && (
                    <div className="ml-3">
                      <iframe
                        src={URL.createObjectURL(selectedDoc)}
                        title={selectedDoc.name}
                        style={{
                          width: "350px",
                          height: "350px",
                          border: "1px solid #ccc",
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal2(false);
              }}
            >
              Close
            </Button>
            {uploadedDocs.length > 0 && (
              <Button
                variant="primary"
                onClick={() =>
                  document.getElementById("formFileMultiple").click()
                }
              >
                Add Documents
              </Button>
            )}
            {uploadedDocs.length > 0 && (
              <Button variant="success" onClick={handleUploadDocuments}>
                Upload Documents
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Col>
  );
};

export default AllArtistsTable;
