import React from "react";
import { BiChevronDown } from "react-icons/bi";
import "./Navbar.css"; // Import the CSS file
import avatar from "../../assets/avatar.png";

function Navbar() {
  return (
    <nav id="navbar" className="nav">
      <h2><mark>Overview</mark></h2>
      <div className="timeline">
        <span>Last 30 Days</span>
        <BiChevronDown />
      </div>
    </nav>
    // <section className="avatar"> {/* Add avatar class here */}
    //   <div className="image">
    //     <img src={avatar} alt="avatar" />
    //   </div>
    //   <div className="name">
    //     <span>Kishan Sheth</span>
    //     <BiChevronDown />
    //   </div>
    // </section>
  );
}

export default Navbar;
